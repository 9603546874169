import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages';
import Privacy from './pages/Privacy';

export default function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/privacy" component={Privacy} />
            </Switch>
        </Router>
    );
}
