import React from 'react';
import logo from '../assets/images/logo.png';

function Hero() {
    return (
        <>
            <section className="appie-hero-area appie-hero-6-area appie-hero-7-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="appie-hero-content appie-hero-content-6 appie-hero-content-7">
                                <h1 className="appie-title">Vigourise your being</h1>
                                <p style={{ padding: "0 20px" }}>
                                    Vigourise is a coaching service by Laksh. I love helping driven athletes with big goals realise their potential in powerlifting.
                                </p>
                                <p style={{ padding: "0 20px" }}>
                                    To enquire about my coaching service, please message me on my instagram to see if I have availability.
                                </p>
                                {/* <p>
                                    Vigourise - definition: attain strength, energy, or enthusiasm.
                                </p> */}

                                <div className="info"></div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="appie-hero-thumb-6">
                                <div
                                    className="thumb wow animated fadeInUp text-center"
                                    data-wow-duration="1000ms"
                                    data-wow-delay="600ms"
                                >
                                    <img src={logo} alt="" height="200" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Hero;
