import React from 'react';
import teamOne from '../assets/images/team-1.jpg';

function Team() {
    return (
        <>
            <section className="appie-team-area pt-90 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Who is behind Vigourise?</h3>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="appie-team-item mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={teamOne} alt="" />
                                    <ul>
                                        <li>
                                            <a href="https://www.instagram.com/vigourise/">
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ padding: "0 20px" }}>
                        <div className="col-lg-3 col-md-6">
                            <div className="text-center">
                                <span></span>
                                <p style={{ padding: "0 20px" }}>
                                    My name is Laksh. I have been a powerlifting coach since 2014, working with lifters at all levels. I have primarily worked as a coach at Newcastle University, where I worked with various lifters. Following are some of my notable accomplishments.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="text-center">
                                <span></span>
                                <p style={{ padding: "0 20px" }}>
                                    In 2016 I put together a team of 8 men to compete in the British University Championship, where I coached 6 of them. We qualified for IPF University Worlds, where I went on to coach the team in  <a href="http://goodlift.info/onecompetition.php?lid=0&cid=361">Minsk, Belarus</a>.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="text-center">
                                <span></span>
                                <p style={{ padding: "0 20px" }}>
                                    In 2017 I repeated with the men's team coaching 6 of those men. I decided I wanted to put together a women's team, so I worked with 7 athletes who competed for the first time. Both teams qualified for University Worlds in South Africa, but we opted not to go.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="text-center">
                                <span></span>
                                <p style={{ padding: "0 20px" }}>
                                    In 2018 I coached a team of 6 men. A new women's team, coaching 5 of them where they were the strongest team in England and qualified for IPF University Worlds, where I coached a small team in <a href="http://goodlift.info/onecompetition.php?lid=0&cid=454">Pilsen, Czech Republic</a>.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="text-center">
                                <span></span>
                                <p style={{ padding: "0 20px" }}>
                                    In 2019 I coached a team of 6 men and a 105kg class university champion. I also coached at very competitive junior nationals that year where my lifter narrowly missed the win. 
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="text-center">
                                <span></span>
                                <p style={{ padding: "0 20px" }}>
                                    All of the above was on top of the lifters I coached that competed at regional competitions or never competed. In 2020 I stopped my role at university and decided to start working privately with individuals; this is where Vigourise began.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Team;
