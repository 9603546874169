import React from 'react';
import Strength from '../assets/images/icon/strength.png';
import Powerlifting from '../assets/images/icon/powerlifting.png';

function Who({ className }) {
    return (
        <section className={`appie-service-area pt-90 pb-100 ${className}`} id="who">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6" style={{ padding: '20px' }}>
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">Who is it for?</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <img src={Strength} alt="" height="50" width="50" />
                            </div>
                            <h4 className="appie-title">Lifter</h4>
                            <p>
                                You want to get as strong as you possibly can and possibly compete. If you have never competed or competed a few times, but think you have great potential for getting stronger, then you are the right person to work with me.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-6 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon">
                                <img src={Powerlifting} alt="" height="50" width="50" />
                            </div>
                            <h4 className="appie-title">Competitor</h4>
                            <p>
                                You want to compete at the highest level you possibly can in powerlifting. If you have big dreams of competing internationally and you know you have great potential, then you are the right person to work with me.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Who;
