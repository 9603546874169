import React from 'react';

function Feature({ className }) {
    return (
        <>
            <section className={`appie-services-2-area pb-100 ${className}`} id="features">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6" style={{ padding: '20px' }}>
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">What do you get?</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-dumbbell" />
                                </div>
                                <h4 className="title">Programming</h4>
                                <p>
                                    I write a training block that is typically 5 - 10 weeks long based on your needs, goals, availability etc. You get the program weekly with adjustments based on your feedback and performance from the prior week. Program evolves as I get to know you better. 
                                    </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-chart-line" />
                                </div>
                                <h4 className="title">Logs and Technique</h4>
                                <p>
                                    You use my app to log your training. You can track your progress and see trends. You can upload your lifting video and attach it to a set on the app; I go through the videos weekly (or sooner) and give feedback on them for technique improvements.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-comments" />
                                </div>
                                <h4 className="title">Communication</h4>
                                <p>
                                    I give feedback via my coaching app and use it for training feedback. I use Instagram for faster response and general communication. I also use video calls for certain athletes competing at a high level or needing extra support. I typically respond within 24 hours.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-utensils" />
                                </div>
                                <h4 className="title">Nutrition</h4>
                                <p>
                                    I am not a nutritionist, but I have excellent foundational knowledge to help you with your nutrition needs. You can track your body weight on the app, and it will create macros suggestions for you. I also help with specific nutrition for competition performance.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-walking" />
                                </div>
                                <h4 className="title">Mobility</h4>
                                <p>
                                    I am not a physiotherapist, but I greatly understand physiology and biomechanics. I have athletes carry out self-mobility assessments via my app every few months; I then create mobility and warmup routines so you can lift better and stay injury-free.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-6 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-mobile-alt" />
                                </div>
                                <h4 className="title">And more...</h4>
                                <p>
                                    I offer a lot more related to powerlifting. E.g. I come up with a game plan for all my athletes competing. I can also come to handle you if I am available; all you need to do is cover my travel costs. Please enquire on Instagram if you have specific needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Feature;
