import Feature from './Feature';
import Footer from './Footer';
import Hero from './Hero';
import Who from './Who';
import Team from './Team';

function Home() {
    return (
        <>
            <Hero />
            <Feature className="pt-90" />
            <Who className="pt-90" />
            <Team />
            <Footer />
        </>
    );
}

export default Home;
